import React from "react"

import SideLayout from "../components/Sidelayout"
import SEO from "../components/seo"

const SecondPage = () => (
  <SideLayout>
    <SEO title="newsletter" />
  </SideLayout>
)

export default SecondPage
